<template>
  <div class="relative -py-1">
    <div
      class="flex overflow-x-auto scroll-smooth scrollbar-hide"
      ref="carousel"
    >
      <div
        ref="carouselInner"
        :aria-label="ariaLabel"
        class="flex gap-x-6 w-full py-1 px-0.5"
      >
        <div v-if="contentCentered" class="flex-1"></div>
        <slot></slot>
        <div v-if="contentCentered" class="flex-1"></div>
      </div>
    </div>

    <div
      class="absolute h-full w-36 left-0 top-0 flex items-center z-10 pointer-events-none transition-all"
      :class="[!isAtStart && canScroll ? 'opacity-100' : 'opacity-0']"
      :style="{
        boxShadow: `inset 60px 0px 20px -35px ${shadowColor}`,
      }"
    >
      <button
        tabindex="-1"
        aria-inert="true"
        aria-hidden="true"
        aria-label="Vorige slide"
        @click="scrollLeft"
        class="flex justify-center items-center bg-los-pink-darkest text-white h-7 w-7 sm:w-9 sm:h-9 rounded-full hover:bg-los-pink-dark transition-all"
        :class="{
          'pointer-events-auto': !isAtStart && canScroll,
        }"
      >
        <FontAwesome icon="chevron-left" />
      </button>
    </div>

    <div
      class="absolute h-full w-36 right-0 top-0 flex items-center justify-end z-10 pointer-events-none transition-all"
      :class="[!isAtEnd && canScroll ? 'opacity-100' : 'opacity-0']"
      :style="{
        boxShadow: `inset -60px 0px 20px -35px ${shadowColor}`,
      }"
    >
      <button
        tabindex="-1"
        aria-inert="true"
        aria-hidden="true"
        aria-label="Volgende slide"
        @click="scrollRight"
        class="flex justify-center items-center bg-los-pink-darkest text-white h-7 w-7 sm:w-9 sm:h-9 rounded-full hover:bg-los-pink-dark transition-all"
        :class="{
          'pointer-events-auto': !isAtEnd && canScroll,
        }"
      >
        <FontAwesome icon="chevron-right" />
      </button>
    </div>
  </div>
</template>

<script setup>
defineProps({
  title: {
    type: String,
    default: '',
  },
  shadowColor: {
    type: String,
    default: 'rgba(255,255,255,1)',
  },
  ariaLabel: {
    type: String,
    default: '',
  },
  contentCentered: {
    type: Boolean,
    default: false,
  },
});

const TIMEOUT = 500;
const SIDE_WITH = 40;

const carousel = ref(null);
const carouselInner = ref(null);

const isAtStart = ref(true);
const isAtEnd = ref(false);
const canScroll = ref(false);

const scrollRight = () => {
  const { scrollWidth, clientWidth, scrollLeft } = carousel.value;
  isAtStart.value = false;

  if (scrollLeft + clientWidth < scrollWidth) {
    carousel.value.scrollTo({
      left: nextRightItem().offsetLeft - SIDE_WITH,
      behavior: 'smooth',
    });

    if (clientWidth + scrollLeft >= scrollWidth - 1) {
      setTimeout(() => {
        isAtEnd.value = true;
      }, TIMEOUT);
    }
  }
};

const scrollLeft = () => {
  const { scrollLeft } = carousel.value;
  isAtEnd.value = false;

  if (scrollLeft > 0) {
    const left =
      nextLeftItem().offsetLeft <= SIDE_WITH
        ? 0
        : nextLeftItem().offsetLeft - SIDE_WITH;
    carousel.value.scrollTo({
      left,
      behavior: 'smooth',
    });
    if (left <= 1) {
      setTimeout(() => {
        isAtStart.value = true;
      }, TIMEOUT);
    }
  }
};

function setupScroll() {
  if (!carousel.value) {
    return;
  }

  const { clientWidth, scrollWidth, scrollLeft } = carousel.value;
  canScroll.value = clientWidth < scrollWidth;
}

function setScrollButtons(e) {
  const { clientWidth, scrollLeft, scrollWidth } = e.target;

  isAtEnd.value = scrollLeft + clientWidth === scrollWidth;
  isAtStart.value = scrollLeft === 0;
}

function nextRightItem() {
  const { clientWidth, scrollLeft } = carousel.value;
  const { children } = carouselInner.value;

  return Array.from(children).find(
    ({ offsetLeft, offsetWidth }) =>
      offsetLeft + offsetWidth > scrollLeft + clientWidth - SIDE_WITH
  );
}

function nextLeftItem() {
  const { clientWidth, scrollLeft } = carousel.value;
  const { children } = carouselInner.value;

  return Array.from(children).find(
    ({ offsetLeft }) => offsetLeft > scrollLeft - clientWidth + SIDE_WITH
  );
}

onMounted(() => {
  nextTick(() => {
    window.addEventListener('resize', setupScroll);
    setupScroll();
  });

  carousel.value.addEventListener('scroll', setScrollButtons);
});
</script>
